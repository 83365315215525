<template>
  <div class="body">
    <Header></Header>
    <sideframe></sideframe>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr />
      <el-row class="dow">
        <div class="content">
          <div v-html="content"></div>
          <hr />
          <h1>公开课报名</h1>
          <div class="card-body">
            <el-button
              :disabled="
                tableData.length == 0
              "
              type="primary"
              @click="onSubmit"
              >提交</el-button
            >
            <el-button @click="addSubmit">新增</el-button>
            <el-table :data="tableData" style="width: 100%">
              <el-table-column label="电话">
                <template slot-scope="scope">
                  <el-input
                    placeholder="电话"
                    v-if="scope.row.phone == null"
                    v-model="form[scope.$index].phone"
                  ></el-input>
                  <span v-else>{{ scope.row.phone }}</span>
                </template>
              </el-table-column>
              <el-table-column label="邮箱">
                <template slot-scope="scope">
                  <el-input
                    placeholder="邮箱"
                    v-if="scope.row.email == null"
                    v-model="form[scope.$index].email"
                  ></el-input>
                  <span v-else>{{ scope.row.email }}</span>
                </template>
              </el-table-column>
              <el-table-column label="公司">
                <template slot-scope="scope">
                  <el-input
                    placeholder="公司"
                    v-if="scope.row.companyName == null"
                    v-model="form[scope.$index].companyName"
                  ></el-input>
                  <span v-else>{{ scope.row.companyName }}</span>
                </template>
              </el-table-column>
              <el-table-column label="岗位">
                <template slot-scope="scope">
                  <el-input
                    placeholder="岗位"
                    v-if="scope.row.post == null"
                    v-model="form[scope.$index].post"
                  ></el-input>
                  <span v-else>{{ scope.row.post }}</span>
                </template>
              </el-table-column>
              <el-table-column  label="操作" width="120">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.phone == null || scope.row.email == null"
                    @click.native.prevent="addRow(scope.$index)"
                    type="text"
                    size="small"
                  >
                    确认
                  </el-button>
                  <el-button
                    @click.native.prevent="deleteRow(scope.$index, tableData)"
                    type="text"
                    size="small"
                  >
                    移除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <asidetitle :asideData="asideData"></asidetitle>
      </el-row>
    </div>
    <flooter></flooter>
  </div>
</template>

<script>
import Flooter from "../../flooter.vue";
import Header from "../../Header.vue";
import Pagination from "@/components/Pagination";
import asidetitle from "@/components/asidetitle.vue";
import Sideframe from "@/components/sideframe.vue";
import { marked } from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";
import breadcrumb from "../../../components/breadcrumb.vue";
import { nanoid } from "nanoid";
import Template from "../../../../../yixuefangce-BackgroundManagement/src/components/template.vue";
marked.setOptions({
  renderer: new marked.Renderer(),
  highlight: function (code) {
    return hljs.highlightAuto(code).value;
  },
  pedantic: false,
  gfm: true,
  tables: true,
  breaks: true,
  sanitize: false,
  smartLists: true,
  smartypants: false,
  xhtml: true,
});
export default {
  components: {
    Header,
    Flooter,
    Pagination,
    Sideframe,
    breadcrumb,
    asidetitle,
    Template,
  },
  data() {
    return {
      form: [],
      dynamicValidateForm: {
        openClassApplicationEntityList: [],
        openClassId: "",
      },
      tableData: [],
      essay: [],
      asideData: {
        asideName: "企业培训",
        data: [
          { _id: nanoid(), title: "销售管理", to: "/salesManagement" },
          { _id: nanoid(), title: "管理技术", to: "/ManagementTechnology" },
          { _id: nanoid(), title: "领导力", to: "/Leadership" },
          { _id: nanoid(), title: "通用素质", to: "/UniversalQuality" },
          {
            _id: nanoid(),
            title: "课程开发与讲师培养",
            to: "/CourseDevelopmentAndInstructorDevelopment",
          },
          { _id: nanoid(), title: "跨界学习", to: "/CrossBorderLearning" },
          { _id: nanoid(), title: "公开课", to: "/PublicClass" },
          { _id: nanoid(), title: "师资", to: "/teachercertification" },
        ],
      },
      titleData: {
        titleName: "",
        data: [
          {
            id: nanoid(),
            name: "企业培训",
            href: "",
          },
          {
            id: nanoid(),
            name: "",
            href: "",
          },
          {
            id: nanoid(),
            name: "",
            href: "",
          },
        ],
      },
      content: "",
    };
  },

  //   computed:{
  // 	  ...mapState(['rows','total','currentPage','pageSize'])
  //   },
  created() {
    this.getParams();
    this.getData();
  },
  watch: {},
  methods: {
    addRow(index) {
      const regexPhone = /^1[3456789]\d{9}$/; // 手机号码的正则表达式
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 邮箱地址的正则表达式

      if (!regexPhone.test(this.form[index].phone)) {
        this.$message.error("请输入正确的手机号码");
      } else if (!regexEmail.test(this.form[index].email)) {
        this.$message.error("请输入正确的邮箱");
      } else if (
        this.form[index].companyName == null ||
        this.form[index].post == null
      ) {
        if (this.form[index].companyName == null) {
          this.form[index].companyName = "无";
        }
        if (this.form[index].post == null) {
          this.form[index].post = "无";
        }
        this.tableData[index].phone = this.form[index].phone;
        this.tableData[index].companyName = this.form[index].companyName;
        this.tableData[index].email = this.form[index].email;
        this.tableData[index].post = this.form[index].post;
      }
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
      this.form.splice(index, 1);
    },
    addSubmit() {
      let data = {
        phone: null,
        email: null,
        companyName: null,
        post: null,
      };
      let data1 = {
        phone: null,
        email: null,
        companyName: null,
        post: null,
      };
      if (this.tableData.length < 10) {
        this.tableData.push(data);
        this.form.push(data1);
      } else {
        this.$message.error("一次性只能添加10条");
      }
    },
    getParams() {
      const ti = this.$route.query.title;
      const name = this.$route.query.name;
      const href = this.$route.query.href;
      this.titleData.titleName = name;
      this.titleData.data[1].name = ti;
      this.titleData.data[1].href = href;
      this.titleData.data[2].name = name;
    },
    async getData() {
      const data = JSON.parse(window.localStorage.getItem("openClass"));
      this.dynamicValidateForm.openClassId = data.openClassTypeId;
      this.content = marked(data.richText, {
        breaks: true,
      }).replace(/<pre>/g, "<pre class='hljs'>");
    },
    onSubmit() {
      let flag = true;
      if (this.tableData.length > 0) {
        this.tableData.forEach((it) => {
          if (it.phone == null || it.email == null) {
            flag = false;
          }
        });
      } else {
        flag = false;
      }
      if (flag) {
        this.dynamicValidateForm.openClassApplicationEntityList =
          this.tableData;
        this.axios
          .post("/openClassApplication/addCourseType", this.dynamicValidateForm)
          .then((res) => {
            if (res.code === 200) {
              this.tableData = []
              this.form = []
              this.$message({
                message: res.msg,
                type: "success",
                duration: 6000
              });
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.$message.error("有预约信息未填写完整");
      }
    },
  },
};
</script>

<style scoped>
::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: rgb(29, 25, 16);
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}
.dow {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: auto;
  width: 80%;
  height: fit-content;
}
hr {
  margin: 0;
  margin-bottom: 8px;
}
.clearfix {
  margin: 20px;
}
.card-body {
  width: 90%;
  height: fit-content;
  margin: 50px auto;
}
.content {
  width: 70%;
}
::v-deep .content h2 {
  text-align: left;
  margin: 0;
  font-weight: lighter;
}

::v-deep .content h3 {
  margin: 0;
  text-align: left;
  background-color: #eeeeee;
  font-weight: normal;
}
::v-deep .content h4 {
  font-weight: normal;
  color: inherit;
  background-color: #eeeeee;
  background: #eee;
}
::v-deep .content img {
  max-width: 800px;
  height: 100%;
  text-align: left;
  margin: 10px;
}
::v-deep .content p {
  display: inline-block;
  text-indent: 2em;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
::v-deep .content table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  vertical-align: middle;
  margin-top: 30px;
}
::v-deep .content th {
  width: 15%;
  background-color: #dff0d8;
}
::v-deep .content td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
}
::v-deep .content table td,
table th {
  position: static;
  float: none;
  display: table-cell;
  padding: 8px;
  line-height: 1.42857143;
  border: 1px solid #ddd;
  border-bottom-width: 2px;
}
@media (max-width: 768px) {
  .el-button {
    width: 40%;
  }
  .clearfix span {
    font-size: 4rem;
  }
  .content {
    width: 90%;
    min-height: 600px;
  }
  .card-body {
    width: 95%;
    margin: auto;
  }
  .content >>> h3 {
    font-size: 4rem;
    line-height: 1.875;
  }
  .content >>> h4 {
    font-size: 3rem;
    line-height: 1.875;
  }
  .content >>> p {
    text-indent: 2em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    line-height: 1.875;
    font-size: 3rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .el-button {
    width: 40%;
  }
  .clearfix span {
    font-size: 3rem;
  }
  .content {
    width: 90%;
    min-height: 600px;
  }
  .card-body {
    width: 90%;
    margin: auto;
  }
  .content >>> h3 {
    font-size: 4rem;
    line-height: 1.875;
  }
  .content >>> p {
    text-indent: 2em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    line-height: 1.875;
    font-size: 3rem;
  }
  .content >>> h4 {
    font-size: 3rem;
    line-height: 1.875;
  }
}

@media (min-width: 1025px) {
  .body-box {
    width: 90%;
  }
  .clearfix span {
    font-size: 1.5rem;
  }
  hr {
    margin-bottom: 8px;
  }
  .content {
    width: 70%;
    min-height: 600px;
  }
  .content >>> h3 {
    font-size: 28px;
    line-height: 1.875;
  }
  .content >>> h4 {
    font-size: 24px;
    line-height: 1.875;
    text-align: left;
  }
  .content >>> p {
    font-size: 20px;
    text-indent: 2em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    line-height: 1.875;
  }
}
</style>